import { useContext } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Context } from 'context/GlobalContext';
import { getDashboardUrl } from 'services/EnvProvider';
import { isSimpleMiningUrl } from 'utils/isSimpleMiningUrl';

const NavigationSignMenu = () => {
  const dashboardUrl = getDashboardUrl();

  const { t } = useTranslation();

  const { isUserLoggedIn } = useContext(Context);

  const redirectToDashboardList = `${dashboardUrl}/account/${isSimpleMiningUrl() ? 'rigs' : 'find-instances'}`;

  return (
    <ul className="sign-menu">
      {!isUserLoggedIn ? (
        <>
          <li>
            <Link href={`${dashboardUrl}/account/register`} legacyBehavior>
              <a className="sign-menu__sign-up btn btn-type-white">
                {t('navigation.signMenu.signUp')}
              </a>
            </Link>
          </li>
          <li>
            <Link href={`${dashboardUrl}/account/login`} legacyBehavior>
              <a className="sign-menu__sign-in btn btn-type-border">
                {t('navigation.signMenu.signInBtn')}
              </a>
            </Link>
          </li>
        </>
      ) : (
        <li>
          <Link href={redirectToDashboardList} legacyBehavior>
            <a className="sign-menu__to-dashboard btn btn-type-white">
              {t('navigation.signMenu.dashboard')}
            </a>
          </Link>
        </li>
      )}
    </ul>
  );
};

export default NavigationSignMenu;
